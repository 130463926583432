.team-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.team-section .left {
    width: 50%;
    padding:  10px 10px 10px 30px;
    position: relative;

}

.team-section .left h1::first-letter{
     color:var(--first-letter);
    font-weight: bold;
    font-size: 50px;
    float: left;
    line-height: 60px;
    padding-right: 8px;
    margin-top: -3px;
    
}

   .team-section .left p {
    margin-bottom: 20px;
    font-size: large;
}
.team-section .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-section .right img{
    width: 100%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    filter: brightness(80%);
transition: .3s;    

}
.team-section .right img:hover{
    filter: brightness(100%);
transition: .3s;    
}


.pedagogie-section{
    position: relative;
    width: 100%;
    margin-top: 1px;
    background-image: url("../assets/images/pedagogie.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.pedagogie-section img{
    display: none;
}
.pedagogie-section .slide-container{
    padding: 20px 10px;

}
.pedagogie-section .slide-container .slide-btn-design{
outline: 0;
border: 0;
padding: 4px 10px;
border-radius: 4px;
background-color: white;
color: black;
font-size: large;
font-weight: 600;
cursor: pointer;
}


.pedagogie-section .slide-container .card{
    padding: 10px 14px;
    height: 200px;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.452);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);

}
.pedagogie-section .card h3{
    text-align: center;
    font-size: 28px;

}
.pedagogie-section .card p{
    font-size: 20px;
    text-align: center;
}
.member-aventage{
margin: 2px 10px 2px 10px ;
height: max-content;
display: flex;
align-items: center;
justify-content: space-between;
text-align: center;

}

.member-aventage img{
    width: 60%;
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    filter: brightness(70%);
    transition: .4s;
}
.member-aventage img:hover{
    filter: brightness(100%);
    transition: .4s;

}
.member-aventage .info h3{
    font-size: xx-large;
}

@media screen and (max-width: 550px){
    .member-aventage{
        flex-direction: column;
    }
    .member-aventage img{
        width: 100%;
    margin-top: 20px;
    clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0% 100%);

    }
    .member-aventage .info h3{
    font-size: large;
}
    .team-section{
        flex-direction: column;
    }
    .team-section .left{
        padding: 10px;

    }
    .team-section .left, .team-section .right{
        width: 100%;
    }
    .team-section .left h1 {
    font-size: large;
}
    .team-section .right img{
    width: 100%;
   clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.pedagogie-section{
    background: none;
}
.pedagogie-section .slide-container{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
     background: var(--first-color);
    margin-top: 1px;
}
.pedagogie-section img{
    display: flex;

    width: 100%;
    clip-path: polygon(0 0, 75% 0, 100% 100%, 30% 100%);
    position: relative;
}
}
.pack-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    font-size: large;
    padding: 0 10px;
    background-color: var(--first-color);
    color: var(--white);
}

.pack-container{
    display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.pack-container .card {
    position: relative;
  min-width: 320px;
  border-radius: 15px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--first-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;

}

.pack-container .card.middle{
background-color: var(--third-color);
}
.pack-container .card .box .content h2{
    background-color: rgb(0, 89, 255);
    color: var(--white);
    font-size: large;
    width: max-content;
    position: relative;
    top: -30px;
    left: -30px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.pack-container .card .box {
  position: relative;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: var(--white-card);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: max-content;
  transition: 0.5s;
}
.pack-container .card .box:hover{
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.pack-container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}




.pack-container .card .box .content h3 {
  font-size: 2rem;
  color: var(--black);
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 25px;

}

.pack-container .card .box .content{
    padding-left: 10px;
}

.pack-container .card .box .content .choose {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: var(--first-color);
margin: 20px ;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s;
}
.pack-container .card .box .content .choose:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background: var(--second-color);
  color: #000;
}

.list {
  list-style: none;
  width: 100%;
  padding: 0;
}

.list__item {
  margin: 0 0 0.5rem 0;
  padding: 0;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}

.label--checkbox {
  position: relative;
  margin: 0.5rem;
  line-height: 135%;
  cursor: pointer;
  width: 100%;
}

.checkbox {
  position: relative;
  top: -0.375rem;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.checkbox:before {
    position: absolute;
    content: "\f00d";
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid red;
}




.checkbox:checked:before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.checkbox:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.1rem;
  height: 1.1rem;
  background: var(--white-card);
  cursor: pointer;
}


.content {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cross{
  color: red;
  margin-right: 10px;
}