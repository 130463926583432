@import url(https://fonts.cdnfonts.com/css/itc-franklin-gothic-std);
:root {
  --black: #121212;
  --first-color: #3B5995;
  --second-color: #DEF30E;
  --third-color: #92D050;
  --white: #fff;
  --white-2: rgb(222, 225, 236);
  --white-3: rgb(163, 161, 161);
  --bg: #f0f0f0;
  --first-letter: #3B5995;
  --white-card: #fff;

  
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  font-family: "ITC Franklin Gothic Std", sans-serif;
}
body::-webkit-scrollbar-track {
  background-color: none;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #3B5995;
  background-color: var(--first-color);
  border-radius: 10px;
}

body {
 margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  background-color: var(--bg);
}



.page-content{
  margin-top: 70px;
  color: #121212;
  color: var(--black);
}
.loading{

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.loading img{

 width: 100px;
}

.btn-design{
    background: linear-gradient(320deg, #3B5995, #DEF30E);
    background: linear-gradient(320deg, var(--first-color), var(--second-color));
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 6px 10px;
  outline: 0;
  border: 0;
  text-decoration: none;
  color: #fff;
  color: var(--white);
  font-size: 17px;
  cursor: pointer;
}

.error, .unsubscribe{
  color: red;
  

}
a{
  text-decoration: none;
}
.nothing{
  font-size: large;
  text-align: center;
  width: 100vw;
  text-transform: uppercase;
}
.to-copy{
  outline: 0;
  border: 0;
  padding: 8px 10px;
  background-color: rgb(64, 12, 250);
  cursor: pointer;
  color: #fff;
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 230px;
}
.unsubscrive-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  flex-direction: column;
  padding: 0 20px;
}
.unsubscrive-container form{
  display: flex;
  flex-direction: column;
}
.unsubscrive-container form label{
  font-size: large;
  text-align: center;
}
.unsubscrive-container input{
  margin: 10px 0;
  outline: 0;
  border: 0;
  font-size: large;
  border-radius: 4px;
  padding: 10px 14px;
width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.unsubscrive-container input[type="submit"]{
  background-color: #95413b;
  cursor: pointer;
  color: #fff;
  color: var(--white);
  padding: 10px 14px;



}
.notfound-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 50px;
}
.notfound-container p{
  font-size: large;
}
.notfound-container input{
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 10px 14px;
  background-color: #3B5995;
  background-color: var(--first-color);
  color: #fff;
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}



.to-top {
  max-width: 80%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}


.dark-container {
  /* position: fixed;
  left: 30px;
  bottom: 30px; */
  width: 30px;
  /* bottom: 20px; */
  align-items: center;
  height: 30px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  font-size: large;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: #121212;
}
.dark-container.white{
  background-color: #fff;
  background-color: var(--white);
}

.dark-container img {
  width: 60%;
  -webkit-filter: invert(0);
          filter: invert(0);
transition: .4s;
  
}
.dark-container img.white{
  -webkit-filter: invert(1);
          filter: invert(1);
transition: .4s;


}

.ft-slick__dots--custom {
    height: 10px;
    width: 10px;
    background-color: #e5e7e9;
    border-radius: 50%;
    position: relative;
  }

  .slick-dots .slick-active {
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    background-color: #DEF30E;
    background-color: var(--second-color);
  }
  .slick-slide > div {
    padding: 10px !important;
  }
  .display-none{
    display: none;
  }
  .custum-file-select{
    cursor: pointer;
  outline: 0;
  border: 0;
  padding: 10px 14px;
  background-color: #3B5995;
  background-color: var(--first-color);
  color: #fff;
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  }
.navbar {
  height: 70px;
  width: 100%;
  background-color:var(--first-color);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  transition: .3s;

  z-index: 99;
  

}
.navbar.shadow{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: .3s;
}
.nav-logo {
  width: 65px;
  position: relative;
}
.nav-logo img {
  width: 100%;
  height: 100%;
  pointer-events: none;

}
.nav-links {
  display: flex;
  grid-gap: 30px;
  list-style: none;
}
.nav-link {
  position: relative;
  text-decoration: none;
  color: var(--white);
  font-size: 15px;
  padding: 0 0 10px 0;
  cursor: pointer;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}



.nav-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}

.nav-link:hover::before {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  
}

.nav-icon {
  display: none;
  cursor: pointer;
  -webkit-filter: invert(1);
          filter: invert(1);
}

.nav-icon img {
  width: 40px;
}

.active-link {
  color: var(--second-color);
}

@media screen and (max-width: 880px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: calc(100vh - 70px);
    top: 50px;
    right: -100%;

    transition: 0.5s all;
  }
  .nav-links.open {
    background-color: var(--first-color);
    right: -40%;
    height: 100vh;
  }
  .nav-link {
    padding: 10px 0;
  }
  .nav-icon {
    display: flex;
  }
}
@media screen and (max-width: 480px) {
  .nav-links.open {
    right: -30%;
  }
}

.team-section{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.team-section .left {
    width: 50%;
    padding:  10px 10px 10px 30px;
    position: relative;

}

.team-section .left h1::first-letter{
     color:var(--first-letter);
    font-weight: bold;
    font-size: 50px;
    float: left;
    line-height: 60px;
    padding-right: 8px;
    margin-top: -3px;
    
}

   .team-section .left p {
    margin-bottom: 20px;
    font-size: large;
}
.team-section .right {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-section .right img{
    width: 100%;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
            clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
transition: .3s;    

}
.team-section .right img:hover{
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
transition: .3s;    
}


.pedagogie-section{
    position: relative;
    width: 100%;
    margin-top: 1px;
    background-image: url(/static/media/pedagogie.821b3e21.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.pedagogie-section img{
    display: none;
}
.pedagogie-section .slide-container{
    padding: 20px 10px;

}
.pedagogie-section .slide-container .slide-btn-design{
outline: 0;
border: 0;
padding: 4px 10px;
border-radius: 4px;
background-color: white;
color: black;
font-size: large;
font-weight: 600;
cursor: pointer;
}


.pedagogie-section .slide-container .card{
    padding: 10px 14px;
    height: 200px;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.452);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    color: white;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);

}
.pedagogie-section .card h3{
    text-align: center;
    font-size: 28px;

}
.pedagogie-section .card p{
    font-size: 20px;
    text-align: center;
}
.member-aventage{
margin: 2px 10px 2px 10px ;
height: -webkit-max-content;
height: max-content;
display: flex;
align-items: center;
justify-content: space-between;
text-align: center;

}

.member-aventage img{
    width: 60%;
    -webkit-clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    transition: .4s;
}
.member-aventage img:hover{
    -webkit-filter: brightness(100%);
            filter: brightness(100%);
    transition: .4s;

}
.member-aventage .info h3{
    font-size: xx-large;
}

@media screen and (max-width: 550px){
    .member-aventage{
        flex-direction: column;
    }
    .member-aventage img{
        width: 100%;
    margin-top: 20px;
    -webkit-clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0% 100%);
            clip-path: polygon(0 1%, 100% 0%, 100% 100%, 0% 100%);

    }
    .member-aventage .info h3{
    font-size: large;
}
    .team-section{
        flex-direction: column;
    }
    .team-section .left{
        padding: 10px;

    }
    .team-section .left, .team-section .right{
        width: 100%;
    }
    .team-section .left h1 {
    font-size: large;
}
    .team-section .right img{
    width: 100%;
   -webkit-clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
           clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
}
.pedagogie-section{
    background: none;
}
.pedagogie-section .slide-container{
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
     background: var(--first-color);
    margin-top: 1px;
}
.pedagogie-section img{
    display: flex;

    width: 100%;
    -webkit-clip-path: polygon(0 0, 75% 0, 100% 100%, 30% 100%);
            clip-path: polygon(0 0, 75% 0, 100% 100%, 30% 100%);
    position: relative;
}
}
.pack-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    font-size: large;
    padding: 0 10px;
    background-color: var(--first-color);
    color: var(--white);
}

.pack-container{
    display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 40px 0;
}

.pack-container .card {
    position: relative;
  min-width: 320px;
  border-radius: 15px;
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--first-color);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;

}

.pack-container .card.middle{
background-color: var(--third-color);
}
.pack-container .card .box .content h2{
    background-color: rgb(0, 89, 255);
    color: var(--white);
    font-size: large;
    width: -webkit-max-content;
    width: max-content;
    position: relative;
    top: -30px;
    left: -30px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}


.pack-container .card .box {
  position: relative;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: var(--white-card);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: -webkit-max-content;
  height: max-content;
  transition: 0.5s;
}
.pack-container .card .box:hover{
box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}

.pack-container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}




.pack-container .card .box .content h3 {
  font-size: 2rem;
  color: var(--black);
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 25px;

}

.pack-container .card .box .content{
    padding-left: 10px;
}

.pack-container .card .box .content .choose {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: var(--first-color);
margin: 20px ;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.5s;
}
.pack-container .card .box .content .choose:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background: var(--second-color);
  color: #000;
}

.list {
  list-style: none;
  width: 100%;
  padding: 0;
}

.list__item {
  margin: 0 0 0.5rem 0;
  padding: 0;
width: 100%;
display: flex;
align-items: center;
justify-content: space-between;
}

.label--checkbox {
  position: relative;
  margin: 0.5rem;
  line-height: 135%;
  cursor: pointer;
  width: 100%;
}

.checkbox {
  position: relative;
  top: -0.375rem;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.checkbox:before {
    position: absolute;
    content: "\f00d";
  transition: all 0.3s ease-in-out;
  content: "";
  position: absolute;
  left: 0;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border: 2px solid red;
}




.checkbox:checked:before {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  height: 0.5rem;
  border-color: #009688;
  border-top-style: none;
  border-right-style: none;
}
.checkbox:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.1rem;
  height: 1.1rem;
  background: var(--white-card);
  cursor: pointer;
}


.content {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@-webkit-keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
@keyframes slideUp {
  0% {
    -webkit-transform: translateY(6.25rem);
    transform: translateY(6.25rem);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.cross{
  color: red;
  margin-right: 10px;
}
header {
  margin: 0;
  padding: 2em 5em 4em;
  color: #efefef;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
.auteur {
  align-self: flex-start;
}
.threeD {
  text-shadow: 0 0.02em 0.02em #e9e9e9, 0 0.02em 0em #696969,
    0 0.04em 0em #636363, 0 0.06em 0.02em #5a5a59, 0 0.08em 0.04em #5e5e5e,
    0 0.1em 0.06em #525252, 0 0.12em 0.06em #4f504f, 0 0.14em 0.04em #4e4e4e,
    0 0.16em 0.02em #464646, 0 0.18em 0em #444444, 0 0.2em 0em #3f3f3f,
    0 0.2em 0.08em rgba(0, 0, 0, 0.2), 0 0.2em 0.16em rgba(0, 0, 0, 0.4),
    0 0.2em 0.24em rgba(0, 0, 0, 0.6), 0 0.28em 0.2em rgba(0, 0, 0, 0.2),
    0 0.36em 0.2em rgba(0, 0, 0, 0.2);
}
header > h3 {
  align-self: flex-end;
}
header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: skewY(-4deg);
          transform: skewY(-4deg);
  background: var(--first-color);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  z-index: -1;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform: skewY(8deg);
          transform: skewY(8deg);
  background: var(--first-color);
  -webkit-transform-origin: bottom right;
          transform-origin: bottom right;
  z-index: -1;
}

@media screen and (max-width: 650px) {
  header {
    margin: 0;
    padding: 2em 1em 4em;
  }
  header > h1 {
    font-size: 22px;
  }
  header > h2 {
    font-size: 18px;
  }
  header > h3 {
    font-size: 14px;
    margin-top: 20px;
  }
  header > h4 {
    font-size: 12px;
  }
}

.footer {
  background-color: var(--first-color);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 40px 60px 40px;
  color: #efefef;
  margin-top: 20px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

}
.footer .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer .flex .part-one {
  border-right: 1px solid var(--white);
  width: 40%;
  margin-right: 20px;
  transition: 0.5s all;
}
.footer .flex .part-one p {
  font-size: 17px;
}
.footer .flex .part-one h4 {
  font-size: 22px;
}
.part-one .social-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: -webkit-max-content;
  width: max-content;
}
.part-one .social-icons img {
  -webkit-filter: invert(1);
          filter: invert(1);
  max-width: 35px;
  margin: 10px 20px;
  cursor: pointer;
}
.footer .flex .part-two {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  position: relative;
}
.footer .flex .part-two form {
  width: 100%;
}


.footer .copyright {
  margin-top: 50px;
}

.footer-links{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
grid-column-gap: 20px;
    list-style: none;
    padding: 0;
}
.footer-links li{
    margin: 10px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
 .top-separator{
     width: 100%;
 }
@media screen and (max-width: 880px) {
  .footer .flex {
    flex-direction: column;
  }
  .footer .flex .part-one {
    border-right: 0;
    border-bottom: 1px solid var(--white);
    padding: 30px 0;
    width: 100%;
    transition: 0.5s all;
  }
  .top-separator{
      border-right: 0;
    border-top: 1px solid var(--white);
    padding: 30px 0;
    width: 100%;
    transition: 0.5s all;
  }
  .footer .flex .part-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer .flex .part-two {
    width: 100%;
    transition: 0.5s all;
  }
}

@media screen and (max-width: 620px) {
  .footer {
    padding: 20px;
    padding-bottom: 70px
  }
  .footer .flex .part-one {
  }
  .footer .flex .part-one .left {
    width: 50%;
  }
  .footer .flex .part-one .right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
  }
  .footer .flex .part-one h4 {
    font-size: 15px;
  }
  .footer .flex .part-one p {
    font-size: 12px;
  }
  .part-one .social-icons img {
    -webkit-filter: invert(1);
            filter: invert(1);
    max-width: 25px;
  }
}

@media screen and (max-width: 350px) {
  .footer-links{
    grid-template-columns: repeat(2, 1fr);

}
}


@media screen and (max-width: 300px) {
  .footer .flex .part-one {
    flex-direction: column;
  }
}
.display-grid{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  
}
.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1.6%;
  background: var(--white-card);
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  border: #fff 1px solid;
}
.blog-card a {
  color: inherit;
}
.blog-card a:hover {
  color: #5ad67d;
}
.blog-card:hover .photo {
  -webkit-transform: scale(1.3) rotate(3deg);
          transform: scale(1.3) rotate(3deg);
}
.blog-card:hover .photo img {
  width: 100%;
}
.blog-card .meta {
  position: relative;
  z-index: 0;
  height: 200px;
  width: 230px;
}
.blog-card .photo {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
}
.blog-card .photo img{
  width: 100%;
}
.blog-card .details,
.blog-card .details ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.blog-card .details {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.blog-card .details a {
  -webkit-text-decoration: dotted underline;
          text-decoration: dotted underline;
}
.blog-card .details ul li {
  display: inline-block;
}
.blog-card .details .author:before {
  margin-right: 10px;
  content: "";
}
.blog-card .details .date:before {
  margin-right: 10px;
  content: "";
}
.blog-card .details .tags ul:before {
  content: "";
  margin-right: 10px;
}
.blog-card .details .tags li {
  margin-right: 2px;
}
.blog-card .details .tags li:first-child {
  margin-left: -4px;
}
.blog-card .description {
  padding: 1rem;
  background: var(--white-card);
  position: relative;
  z-index: 1;
}
.blog-card .description h1,
.blog-card .description h2 {
  font-family: Poppins, sans-serif;
}
.blog-card .description h1 {
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}
.blog-card .description h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #a2a2a2;
  margin-top: 5px;
}
.blog-card .description .read-more {
  text-align: right;
}
.blog-card .description .read-more a {
  color: #5ad67d;
  display: inline-block;
  position: relative;
  margin: 0 20px;
}
.blog-card .description .read-more a:after {
  content: "";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.blog-card .description .read-more a:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.blog-card p {
  position: relative;
  margin: 1rem 0 0;
}
.blog-card p:first-of-type {
  margin-top: 1.25rem;
}
.blog-card p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #5ad67d;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
.blog-card:hover .details {
  left: 0%;
}
@media (min-width: 640px) {
  .blog-card {
    flex-direction: row;
    max-width: 700px;
  }
  .blog-card .meta {
    flex-basis: 40%;
    height: auto;
  }
  .blog-card .description {
    flex-basis: 60%;
  }
  .blog-card .description:before {
    -webkit-transform: skewX(-3deg);
            transform: skewX(-3deg);
    content: "";
    background: var(--white-card);
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  
}


@media (max-width: 880px) {
  .display-grid{
  grid-template-columns: 1fr ;
  }
  
}

.newsletter {
  overflow: hidden;
  height: -webkit-max-content;
  height: max-content;
  width: 400px;
  margin: 0 auto;
  position: relative;

}
.newsletter input {
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  padding: 10px 0 10px 15px;
  outline: none;
  transition: border 0.2s ease;

}
.newsletter input:focus {
  border: 1px solid #999;
}
.newsletter input:focus + button {
  background-color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

}
.newsletter button {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  border: none;
  background-color: #666;
  transition: background-color 0.5s ease, width 0.3s ease;
  color: white;
  padding: 0;
  margin: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-transform: uppercase;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: .5s;

  @inlude transform(translateZ(0));
}
.newsletter button:hover {
  width: 140px;
}
.newsletter button.sended {
  width: 100%;
  height: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(12, 107, 32);
  cursor: auto;
  transition: .5s;

}
.newsletter button.notsend{
  width: 100%;
  height: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(107, 12, 12);
  transition: .5s;
}
.about-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.about-section p{
    margin: 10px;
    font-size: large;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.about-section p span{
    margin: 10px 0;
}
.about-section img{
    width: 50%;
    margin: 10px;
}

.about-section input{
    margin-top: 20px;
}
@media screen and (max-width: 550px){
    .about-section {
    flex-direction: column;
}
.about-section img{
    width: 100%;
    margin: 0;
}
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.wrapper .panel {
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  float: left;
  width: 300px;
  height: 500px;
  text-align: center;
  background: var(--bg);
  border: 1px solid var(--second-color);
  box-sizing: border-box;
  transition: border 200ms ease;
  border-radius: 10px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: perspective(1000px);
          transform: perspective(1000px);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.wrapper .panel.portoflio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-max-content;
  height: max-content;
}
.wrapper .panel.portoflio .tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--first-color);
  color: var(--white);
  padding: 8px;
  border-radius: 8px;
}
.wrapper .panel:hover {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.18);
  transition: 0.5s;
}
.wrapper .panel img {
  width: 100%;
}
.wrapper .panel h3 {
  display: block;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.wrapper .panel p {
  font-size: 14px;
  padding: 0 6px 10px 6px;
  text-align: left;
  height: -webkit-max-content;
  height: max-content;
}

.wrapper .panel p span {
  color: var(--black);
  font-weight: 700px;
  font-size: 18px;
  text-shadow: 1px 1px 0px #000;
  margin-right: 10px;
}
.wrapper .panel .slide {
  position: absolute;
  bottom: -550px;
  left: 0;
  z-index: 100;
  padding: 20px;
  height: 100%;
  width: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  transition: all 300ms 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.wrapper .panel .slide h4 {
  margin-bottom: 20px;
  text-align: left;
  text-shadow: none;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
}
.wrapper .panel .slide ul li {
  padding: 5px 5px 5px 8px;
  line-height: 24px;
  font-size: 13px;
  color: #f0f0f0;
  border-bottom: 1px solid #0171f1;
  list-style: none;
}

.wrapper .panel .slide ul li:last-child {
  border: 0;
}

.panel:hover {
  border: 1px solid #30bb57;
}
.panel:hover .slide {
  bottom: 0;
  cursor: pointer;
}

p.title {
  margin: 0 auto;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 36px;
  width: 890px;
  background: #013d47;
  border: 1px solid #2ba74e;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 910px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 590px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapper .panel p {
    font-size: 13.5px;
  }
}

.contact-section {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
section .contact-container {
  position: relative;
  min-width: 80%;
  min-height: 100%;
  display: flex;
  z-index: 8;
  align-items: center;
}

section .contact-container .contact-info {
  width: 350px;
  height: calc(100% - 180px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
}
section .contact-container .contact-info h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
section .contact-container .contact-info .info {
  position: relative;
  margin: 20px 0;
}
section .contact-container .contact-info .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  align-items: flex-start;
}

section .contact-container .contact-info .info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
}

section .contact-container .contact-info .info li span:nth-child(1) img {
  max-width: 100%;
  -webkit-filter: invert(1);
          filter: invert(1);
  opacity: 0.5;
}

section .contact-container .contact-info .info li span:nth-child(2) {
  color: #fff;
  margin-left: 10px;
  font-weight: 300;
  opacity: 0.5;
}
section .contact-container .contact-info .info li:hover span:nth-child(1) img,
section .contact-container .contact-info .info li:hover span:nth-child(2) {
  opacity: 1;
}

section .contact-container .contact-info .sci {
  position: relative;
  display: flex;
}
section .contact-container .contact-info .sci li {
  list-style: none;
  margin-right: 15px;
}
section .contact-container .contact-info .sci li a {
  text-decoration: none;
}
section .contact-container .contact-info .sci li a img {
  -webkit-filter: invert(1);
          filter: invert(1);
  opacity: 0.5;
  max-width: 32px;
}
section .contact-container .contact-info .sci li:hover a img {
  opacity: 1;
}

section .contact-container .contact-form {
  position: absolute;
  padding: 0 50px;
  background-color: var(--white-card);
  margin-left: 200px;
  padding-left: 250px;
  width: 80%;
  height: -webkit-max-content;
  height: max-content;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  border-left: 0;
}
section .contact-container .contact-form h2 {
  color: var(--black);
  font-size: 24px;
  font-weight: 500;
}
section .contact-container .contact-form .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
section .contact-container .contact-form .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}
section .contact-container .contact-form .formBox .inputBox.w50 {
  width: 47%;
}
section .contact-container .contact-form .formBox .inputBox.w100 {
  width: 100%;
}
section .contact-container .contact-form .formBox .inputBox input,
section .contact-container .contact-form .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--black);
  resize: none;
  background-color: var(--white-card);
}
section .contact-container .contact-form .formBox .inputBox textarea {
  min-height: 120px;
}
section .contact-container .contact-form .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: var(--black);
  transition: 0.3s;
  pointer-events: none;
}

section .contact-container .contact-form .formBox .inputBox input:focus ~ span,
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  textarea:focus
  ~ span,
section .contact-container .contact-form .formBox .inputBox input:valid ~ span,
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  textarea:valid
  ~ span {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--second-color);
}
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  input[type="submit"] {
  position: relative;
  cursor: pointer;
  color: #fff;
  border: none;
  background: linear-gradient(320deg, var(--first-color), var(--second-color));
  padding: 10px 18px;
  margin: 30px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}
@media screen and (max-width: 960px) {
  .contact-section {
    height: auto;
  }
  section .contact-container {
    flex-direction: column;
    min-width: 100%;
    justify-content: center;
    align-items: center;
  }
  section .contact-container .contact-info {
    position: relative;
    width: 60%;
  }
  section .contact-container .contact-form {
    position: relative;
    margin-left: 0;
    top: -50px;
    padding: 0 20px;
    padding-top: 50px;
    width: 92%;
    border: 0.1px solid #fff;
    border-top: 0;
  }
}

@media screen and (max-width: 588px) {
  section .contact-container .contact-form .formBox {
    flex-direction: column;
  }
  section .contact-container .contact-form .formBox .inputBox.w50 {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  section .contact-container .contact-info {
    padding: 4px;
    width: 290px;
  }
}


.view-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    

}
.view-container .card{
width: 90%;
    background-color: var(--white-card);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    padding: 10px;
    position: relative;
    border: 1px solid #fff;
    

}
.view-container .card .img{
    width: 40%;
    position: relative;
}
.view-container .card .img img{
    width: 100%;
}
.view-container .card .info{
    width: 60%;
    position: relative;
    padding: 0 20px;
}

.view-container .card .info h1{
    margin: 0;
    font-size: xx-large;
}
.view-container .card .info .description{
    letter-spacing: 1px;
    width: 80%;
    margin: 0;
}
.view-container .card .date{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    font-size: large;
}
@media screen and (max-width: 780px) {
    .view-container .card{
        flex-direction: column;
        width: 96%;
    }
    .view-container .card .img{
    width: 100%;
}
 .view-container .card .img img{
    width: 100%;
}
.view-container .card .info{
    width: 100%;
    padding: 0;
}
.view-container .card .info .date {
    width: 100%;
}
.view-container .card .info h1{
   font-size: x-large;
   text-align: center;
}
}
.search-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}
.search-input {
  -webkit-appearance: none;
  padding: 7px 7px 7px 12px;
  width: 50%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  outline: 0;
  transition: all 150ms ease;
}
.search-input:focus{
    border: 1px solid var(--third-color);
    transition: all 150ms ease;
}

@media screen and (max-width: 580px) {
    .search-input{
        width: 90%;
    }
}

ol.gradient-list > li, ol.gradient-list > li::before {
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.list-container {
	display: block;
	margin: 0 auto;
	max-width: 40rem;
	padding: 1rem;
}


ol.gradient-list {
	counter-reset: gradient-counter;
	list-style: none;
	margin: 1.75rem 0;
	padding-left: 1rem;
}

ol.gradient-list > li {
	background: var(--white-card);
	border-radius: 0 0.5rem 0.5rem 0.5rem;
	counter-increment: gradient-counter;
	margin-top: 1rem;
	min-height: 3rem;
	padding: 1rem 1rem 1rem 3rem;
	position: relative;
    font-size: large;
	border: 1px solid var(--white);
}

ol.gradient-list > li::before, ol.gradient-list > li::after {
	background: linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%);
	border-radius: 1rem 0 1rem 1rem;
	content: '';
	height: 3rem;
	left: -1rem;
	overflow: hidden;
	position: absolute;
	top: -1rem;
	width: 3rem;
}

ol.gradient-list > li::before {
	align-items: flex-end;
	content: counter(gradient-counter);
	color: #1d1f20;
	display: flex;
    font-size: 26px;
    font-weight: 650;
	justify-content: flex-end;
	padding: 0.125em 0.25em;
	z-index: 1;
}

ol.gradient-list > li:nth-child(10n+1):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
}

ol.gradient-list > li:nth-child(10n+2):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}

ol.gradient-list > li:nth-child(10n+3):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}

ol.gradient-list > li:nth-child(10n+4):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}

ol.gradient-list > li:nth-child(10n+5):before {
	background: linear-gradient(135deg, #a2ed56 0%, #fddc32 100%);
}

ol.gradient-list > li:nth-child(10n+6):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.8) 0%, rgba(253, 220, 50, 0.8) 100%);
}

ol.gradient-list > li:nth-child(10n+7):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.6) 0%, rgba(253, 220, 50, 0.6) 100%);
}

ol.gradient-list > li:nth-child(10n+8):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.4) 0%, rgba(253, 220, 50, 0.4) 100%);
}

ol.gradient-list > li:nth-child(10n+9):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0.2) 0%, rgba(253, 220, 50, 0.2) 100%);
}

ol.gradient-list > li:nth-child(10n+10):before {
	background: linear-gradient(135deg, rgba(162, 237, 86, 0) 0%, rgba(253, 220, 50, 0) 100%);
}

ol.gradient-list > li + li {
	margin-top: 2rem;
}


.formation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
  gap: 2rem;
  margin: 4rem 3vw;
  padding: 0;
}

.formation-grid .card {
  position: relative;
  display: block;
  height: -webkit-max-content;
  height: max-content;  
  border-radius: calc(20 * 1px);
  text-decoration: none;
  width: 300px;
  background: var(--white-card);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border: 1px solid #fff;
}
.formation-grid .card .read-more a {
 margin-left: 10px;
 background-color: var(--first-color);
 color: var(--white);
 padding: 3px 6px;
 border-radius: 10px;
}
.formation-grid .card__image {      
  width: 100%;
  height: auto;
  border-radius: calc(20 * 1px) calc(20 * 1px) 0 0;    

}

.formation-grid .card__header {
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 2em;
  gap: 2em;
  padding: 1em;
  border-radius: calc(20 * 1px) 0 calc(20 * 1px) calc(20 * 1px);    
  background: var(--white-card);
  transition: .2s ease-in-out;
}

.card__title {
  font-size: large;
  margin: 0 0 .3em;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card .img small{
  position: absolute;
  right: -20px;
  top: -10px;
  background-color: var(--first-color);
  font-size: large;
  padding: 10px;
  border-radius: 10px;
  color: var(--white);
}

.card__status {
  font-size: 1em;
  color: var(--black);
}
   
@media screen and (max-width: 330px) {
  .card .img small{
  left: 0;
  right: inherit;
  top: 0;
}
}
.popup-container{
    background-color: rgba(2, 2, 2, 0.384);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 98;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-container .modal{
    height: 600px;
    width: 300px;
    background-color: var(--white-card);
    position: relative;
}
.popup-container .modal .close-btn{
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}
.pack-form{
  padding: 0 50px;
  background-color: var(--white-card);
  width: 100%;
  height: 100%;
  height: -webkit-max-content;
  height: max-content;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-left: 0;
}
.pack-form .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
.pack-form .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}
.pack-form .formBox .inputBox.padd {
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid var(--black);
  background-color: var(--white-card);
  color: var(--black);
}
.pack-form .formBox .inputBox.w50 {
  width: 47%;
}
.pack-form .formBox .inputBox.w100 {
  width: 100%;
}

.pack-form .formBox .inputBox input,
.pack-form .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--black);
  resize: none;
  background-color: var(--white-card);
}
.pack-form .formBox .inputBox textarea {
  min-height: 120px;
}
.pack-form .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: var(--black);
  transition: 0.3s;
  pointer-events: none;
}

.pack-form  .formBox .inputBox input:focus ~ span,
.pack-form 
  .formBox
  .inputBox
  textarea:focus
  ~ span,
.pack-form  .formBox .inputBox input:valid ~ span,
.pack-form 
  .formBox
  .inputBox
  textarea:valid
  ~ span {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--second-color);
}
.pack-form 
  .formBox
  .inputBox
  input[type="submit"] {
  position: relative;
  cursor: pointer;
  color: #fff;
  border: none;
  background: linear-gradient(320deg, var(--first-color), var(--second-color));
  padding: 10px 18px;
  margin: 30px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}
.toclose{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: x-large;
}


@media screen and (max-width: 960px) {
  
 .pack-form  {
    position: relative;
    margin-left: 0;
    padding: 0 20px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 588px) {

 
  .pack-form 
  .formBox {
    flex-direction: column;
  }
  .pack-form 
  .formBox .inputBox.w50 {
    width: 100%;
  }
}




.sendbox-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: transparent;
  inset: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendbox-container .msg-box {
  background-color: var(--third-color);
  padding: 50px 100px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
.sendbox-container .msg-box p {
  color: var(--white-card);
  font-size: 26px;
  text-align: center;
}
.sendbox-container .msg-box input {
  cursor: pointer;
}

