.contact-section {
  width: 100vw;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
section .contact-container {
  position: relative;
  min-width: 80%;
  min-height: 100%;
  display: flex;
  z-index: 8;
  align-items: center;
}

section .contact-container .contact-info {
  width: 350px;
  height: calc(100% - 180px);
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.2);
}
section .contact-container .contact-info h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
section .contact-container .contact-info .info {
  position: relative;
  margin: 20px 0;
}
section .contact-container .contact-info .info li {
  position: relative;
  list-style: none;
  display: flex;
  margin: 20px 0;
  cursor: pointer;
  align-items: flex-start;
}

section .contact-container .contact-info .info li span:nth-child(1) {
  width: 30px;
  min-width: 30px;
}

section .contact-container .contact-info .info li span:nth-child(1) img {
  max-width: 100%;
  filter: invert(1);
  opacity: 0.5;
}

section .contact-container .contact-info .info li span:nth-child(2) {
  color: #fff;
  margin-left: 10px;
  font-weight: 300;
  opacity: 0.5;
}
section .contact-container .contact-info .info li:hover span:nth-child(1) img,
section .contact-container .contact-info .info li:hover span:nth-child(2) {
  opacity: 1;
}

section .contact-container .contact-info .sci {
  position: relative;
  display: flex;
}
section .contact-container .contact-info .sci li {
  list-style: none;
  margin-right: 15px;
}
section .contact-container .contact-info .sci li a {
  text-decoration: none;
}
section .contact-container .contact-info .sci li a img {
  filter: invert(1);
  opacity: 0.5;
  max-width: 32px;
}
section .contact-container .contact-info .sci li:hover a img {
  opacity: 1;
}

section .contact-container .contact-form {
  position: absolute;
  padding: 0 50px;
  background-color: var(--white-card);
  margin-left: 200px;
  padding-left: 250px;
  width: 80%;
  height: max-content;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border: 1px solid #fff;
  border-left: 0;
}
section .contact-container .contact-form h2 {
  color: var(--black);
  font-size: 24px;
  font-weight: 500;
}
section .contact-container .contact-form .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
section .contact-container .contact-form .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}
section .contact-container .contact-form .formBox .inputBox.w50 {
  width: 47%;
}
section .contact-container .contact-form .formBox .inputBox.w100 {
  width: 100%;
}
section .contact-container .contact-form .formBox .inputBox input,
section .contact-container .contact-form .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--black);
  resize: none;
  background-color: var(--white-card);
}
section .contact-container .contact-form .formBox .inputBox textarea {
  min-height: 120px;
}
section .contact-container .contact-form .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: var(--black);
  transition: 0.3s;
  pointer-events: none;
}

section .contact-container .contact-form .formBox .inputBox input:focus ~ span,
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  textarea:focus
  ~ span,
section .contact-container .contact-form .formBox .inputBox input:valid ~ span,
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  textarea:valid
  ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--second-color);
}
section
  .contact-container
  .contact-form
  .formBox
  .inputBox
  input[type="submit"] {
  position: relative;
  cursor: pointer;
  color: #fff;
  border: none;
  background: linear-gradient(320deg, var(--first-color), var(--second-color));
  padding: 10px 18px;
  margin: 30px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}
@media screen and (max-width: 960px) {
  .contact-section {
    height: auto;
  }
  section .contact-container {
    flex-direction: column;
    min-width: 100%;
    justify-content: center;
    align-items: center;
  }
  section .contact-container .contact-info {
    position: relative;
    width: 60%;
  }
  section .contact-container .contact-form {
    position: relative;
    margin-left: 0;
    top: -50px;
    padding: 0 20px;
    padding-top: 50px;
    width: 92%;
    border: 0.1px solid #fff;
    border-top: 0;
  }
}

@media screen and (max-width: 588px) {
  section .contact-container .contact-form .formBox {
    flex-direction: column;
  }
  section .contact-container .contact-form .formBox .inputBox.w50 {
    width: 100%;
  }
}

@media screen and (max-width: 440px) {
  section .contact-container .contact-info {
    padding: 4px;
    width: 290px;
  }
}
