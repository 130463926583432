

.formation-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 3vw;
  padding: 0;
}

.formation-grid .card {
  position: relative;
  display: block;
  height: max-content;  
  border-radius: calc(20 * 1px);
  text-decoration: none;
  width: 300px;
  background: var(--white-card);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border: 1px solid #fff;
}
.formation-grid .card .read-more a {
 margin-left: 10px;
 background-color: var(--first-color);
 color: var(--white);
 padding: 3px 6px;
 border-radius: 10px;
}
.formation-grid .card__image {      
  width: 100%;
  height: auto;
  border-radius: calc(20 * 1px) calc(20 * 1px) 0 0;    

}

.formation-grid .card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1em;
  border-radius: calc(20 * 1px) 0 calc(20 * 1px) calc(20 * 1px);    
  background: var(--white-card);
  transition: .2s ease-in-out;
}

.card__title {
  font-size: large;
  margin: 0 0 .3em;
  color: var(--black);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card .img small{
  position: absolute;
  right: -20px;
  top: -10px;
  background-color: var(--first-color);
  font-size: large;
  padding: 10px;
  border-radius: 10px;
  color: var(--white);
}

.card__status {
  font-size: 1em;
  color: var(--black);
}
   
@media screen and (max-width: 330px) {
  .card .img small{
  left: 0;
  right: inherit;
  top: 0;
}
}