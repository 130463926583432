.newsletter {
  overflow: hidden;
  height: max-content;
  width: 400px;
  margin: 0 auto;
  position: relative;

}
.newsletter input {
  width: 100%;
  border: 1px solid white;
  border-radius: 5px;
  height: 40px;
  padding: 10px 0 10px 15px;
  outline: none;
  -moz-transition: border 0.2s ease;
  -o-transition: border 0.2s ease;
  -webkit-transition: border 0.2s ease;
  transition: border 0.2s ease;

}
.newsletter input:focus {
  border: 1px solid #999;
}
.newsletter input:focus + button {
  background-color: #000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

}
.newsletter button {
  position: absolute;
  right: 2px;
  top: 2px;
  height: 36px;
  border: none;
  background-color: #666;
  -moz-transition: background-color 0.5s ease, width 0.3s ease;
  -o-transition: background-color 0.5s ease, width 0.3s ease;
  -webkit-transition: background-color 0.5s ease, width 0.3s ease;
  transition: background-color 0.5s ease, width 0.3s ease;
  color: white;
  padding: 0;
  margin: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-transform: uppercase;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition: .5s;

  @inlude transform(translateZ(0));
}
.newsletter button:hover {
  width: 140px;
}
.newsletter button.sended {
  width: 100%;
  height: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(12, 107, 32);
  cursor: auto;
  transition: .5s;

}
.newsletter button.notsend{
  width: 100%;
  height: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 4px;
  background-color: rgb(107, 12, 12);
  transition: .5s;
}