.navbar {
  height: 70px;
  width: 100%;
  background-color:var(--first-color);
  color: var(--black);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  font-size: 1.2rem;
  position: fixed;
  top: 0;
  transition: .3s;

  z-index: 99;
  

}
.navbar.shadow{
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  transition: .3s;
}
.nav-logo {
  width: 65px;
  position: relative;
}
.nav-logo img {
  width: 100%;
  height: 100%;
  pointer-events: none;

}
.nav-links {
  display: flex;
  grid-gap: 30px;
  list-style: none;
}
.nav-link {
  position: relative;
  text-decoration: none;
  color: var(--white);
  font-size: 15px;
  padding: 0 0 10px 0;
  cursor: pointer;
  transition: transform 0.3s ease;
}



.nav-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: var(--white);
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.nav-link:hover::before {
  transform: scaleX(1);
  
}

.nav-icon {
  display: none;
  cursor: pointer;
  filter: invert(1);
}

.nav-icon img {
  width: 40px;
}

.active-link {
  color: var(--second-color);
}

@media screen and (max-width: 880px) {
  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100%;
    height: calc(100vh - 70px);
    top: 50px;
    right: -100%;

    transition: 0.5s all;
  }
  .nav-links.open {
    background-color: var(--first-color);
    right: -40%;
    height: 100vh;
  }
  .nav-link {
    padding: 10px 0;
  }
  .nav-icon {
    display: flex;
  }
}
@media screen and (max-width: 480px) {
  .nav-links.open {
    right: -30%;
  }
}
