@import url("https://fonts.cdnfonts.com/css/itc-franklin-gothic-std");
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


:root {
  --black: #121212;
  --first-color: #3B5995;
  --second-color: #DEF30E;
  --third-color: #92D050;
  --white: #fff;
  --white-2: rgb(222, 225, 236);
  --white-3: rgb(163, 161, 161);
  --bg: #f0f0f0;
  --first-letter: #3B5995;
  --white-card: #fff;

  
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  font-family: "ITC Franklin Gothic Std", sans-serif;
}
body::-webkit-scrollbar-track {
  background-color: none;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: var(--first-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

body {
 margin: 0;
  padding: 0;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  background-color: var(--bg);
}



.page-content{
  margin-top: 70px;
  color: var(--black);
}
.loading{

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.loading img{

 width: 100px;
}

.btn-design{
    background: linear-gradient(320deg, var(--first-color), var(--second-color));
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 6px 10px;
  outline: 0;
  border: 0;
  text-decoration: none;
  color: var(--white);
  font-size: 17px;
  cursor: pointer;
}

.error, .unsubscribe{
  color: red;
  

}
a{
  text-decoration: none;
}
.nothing{
  font-size: large;
  text-align: center;
  width: 100vw;
  text-transform: uppercase;
}
.to-copy{
  outline: 0;
  border: 0;
  padding: 8px 10px;
  background-color: rgb(64, 12, 250);
  cursor: pointer;
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: 230px;
}
.unsubscrive-container{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
  flex-direction: column;
  padding: 0 20px;
}
.unsubscrive-container form{
  display: flex;
  flex-direction: column;
}
.unsubscrive-container form label{
  font-size: large;
  text-align: center;
}
.unsubscrive-container input{
  margin: 10px 0;
  outline: 0;
  border: 0;
  font-size: large;
  border-radius: 4px;
  padding: 10px 14px;
width: 300px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.unsubscrive-container input[type="submit"]{
  background-color: #95413b;
  cursor: pointer;
  color: var(--white);
  padding: 10px 14px;



}
.notfound-container{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 50px;
}
.notfound-container p{
  font-size: large;
}
.notfound-container input{
  cursor: pointer;
  outline: 0;
  border: 0;
  padding: 10px 14px;
  background-color: var(--first-color);
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}



.to-top {
  max-width: 80%;
  transform: rotate(180deg);
}


.dark-container {
  /* position: fixed;
  left: 30px;
  bottom: 30px; */
  width: 30px;
  /* bottom: 20px; */
  align-items: center;
  height: 30px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  border-radius: 50%;
  font-size: large;
  outline: 0;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: #121212;
}
.dark-container.white{
  background-color: var(--white);
}

.dark-container img {
  width: 60%;
  filter: invert(0);
transition: .4s;
  
}
.dark-container img.white{
  filter: invert(1);
transition: .4s;


}

.ft-slick__dots--custom {
    height: 10px;
    width: 10px;
    background-color: #e5e7e9;
    border-radius: 50%;
    position: relative;
  }

  .slick-dots .slick-active {
    transition: width 0.3s ease-in-out;
  }

  .slick-dots .slick-active .ft-slick__dots--custom {
    background-color: var(--second-color);
  }
  .slick-slide > div {
    padding: 10px !important;
  }
  .display-none{
    display: none;
  }
  .custum-file-select{
    cursor: pointer;
  outline: 0;
  border: 0;
  padding: 10px 14px;
  background-color: var(--first-color);
  color: var(--white);
  font-size: large;
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  width: 100%;
  }