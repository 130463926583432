.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.wrapper .panel {
  position: relative;
  margin: 20px auto;
  overflow: hidden;
  float: left;
  width: 300px;
  height: 500px;
  text-align: center;
  background: var(--bg);
  border: 1px solid var(--second-color);
  box-sizing: border-box;
  transition: border 200ms ease;
  border-radius: 10px;
  transform-style: preserve-3d;
  transform: perspective(1000px);
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transition: 0.5s;
}
.wrapper .panel.portoflio {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: max-content;
}
.wrapper .panel.portoflio .tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--first-color);
  color: var(--white);
  padding: 8px;
  border-radius: 8px;
}
.wrapper .panel:hover {
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.18);
  transition: 0.5s;
}
.wrapper .panel img {
  width: 100%;
}
.wrapper .panel h3 {
  display: block;
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
}
.wrapper .panel p {
  font-size: 14px;
  padding: 0 6px 10px 6px;
  text-align: left;
  height: max-content;
}

.wrapper .panel p span {
  color: var(--black);
  font-weight: 700px;
  font-size: 18px;
  text-shadow: 1px 1px 0px #000;
  margin-right: 10px;
}
.wrapper .panel .slide {
  position: absolute;
  bottom: -550px;
  left: 0;
  z-index: 100;
  padding: 20px;
  height: 100%;
  width: 100%;
  text-align: left;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  transition: all 300ms 500ms cubic-bezier(0.645, 0.045, 0.355, 1);
}
.wrapper .panel .slide h4 {
  margin-bottom: 20px;
  text-align: left;
  text-shadow: none;
  font-size: 18px;
  font-weight: 600;
  color: var(--white);
}
.wrapper .panel .slide ul li {
  padding: 5px 5px 5px 8px;
  line-height: 24px;
  font-size: 13px;
  color: #f0f0f0;
  border-bottom: 1px solid #0171f1;
  list-style: none;
}

.wrapper .panel .slide ul li:last-child {
  border: 0;
}

.panel:hover {
  border: 1px solid #30bb57;
}
.panel:hover .slide {
  bottom: 0;
  cursor: pointer;
}

p.title {
  margin: 0 auto;
  padding: 0;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  height: 36px;
  width: 890px;
  background: #013d47;
  border: 1px solid #2ba74e;
  color: #fff;
  text-align: center;
  border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 910px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 590px) {
  .wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
  .wrapper .panel p {
    font-size: 13.5px;
  }
}
