.popup-container{
    background-color: rgba(2, 2, 2, 0.384);
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 98;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.popup-container .modal{
    height: 600px;
    width: 300px;
    background-color: var(--white-card);
    position: relative;
}
.popup-container .modal .close-btn{
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}