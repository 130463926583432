.sendbox-container {
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-color: transparent;
  inset: 0;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendbox-container .msg-box {
  background-color: var(--third-color);
  padding: 50px 100px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
}
.sendbox-container .msg-box p {
  color: var(--white-card);
  font-size: 26px;
  text-align: center;
}
.sendbox-container .msg-box input {
  cursor: pointer;
}
