.pack-form{
  padding: 0 50px;
  background-color: var(--white-card);
  width: 100%;
  height: 100%;
  height: max-content;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
  border-left: 0;
}
.pack-form .formBox {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}
.pack-form .formBox .inputBox {
  position: relative;
  margin: 0 0 35px 0;
}
.pack-form .formBox .inputBox.padd {
  padding: 10px 0;
  border: 0;
  border-bottom: 1px solid var(--black);
  background-color: var(--white-card);
  color: var(--black);
}
.pack-form .formBox .inputBox.w50 {
  width: 47%;
}
.pack-form .formBox .inputBox.w100 {
  width: 100%;
}

.pack-form .formBox .inputBox input,
.pack-form .formBox .inputBox textarea {
  width: 100% !important;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: #333;
  border: none;
  outline: none;
  border-bottom: 1px solid var(--black);
  resize: none;
  background-color: var(--white-card);
}
.pack-form .formBox .inputBox textarea {
  min-height: 120px;
}
.pack-form .formBox .inputBox span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 18px;
  font-weight: 300;
  color: var(--black);
  transition: 0.3s;
  pointer-events: none;
}

.pack-form  .formBox .inputBox input:focus ~ span,
.pack-form 
  .formBox
  .inputBox
  textarea:focus
  ~ span,
.pack-form  .formBox .inputBox input:valid ~ span,
.pack-form 
  .formBox
  .inputBox
  textarea:valid
  ~ span {
  transform: translateY(-20px);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  color: var(--second-color);
}
.pack-form 
  .formBox
  .inputBox
  input[type="submit"] {
  position: relative;
  cursor: pointer;
  color: #fff;
  border: none;
  background: linear-gradient(320deg, var(--first-color), var(--second-color));
  padding: 10px 18px;
  margin: 30px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}
.toclose{
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: x-large;
}


@media screen and (max-width: 960px) {
  
 .pack-form  {
    position: relative;
    margin-left: 0;
    padding: 0 20px;
    padding-top: 50px;
  }
}

@media screen and (max-width: 588px) {

 
  .pack-form 
  .formBox {
    flex-direction: column;
  }
  .pack-form 
  .formBox .inputBox.w50 {
    width: 100%;
  }
}


