.about-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}
.about-section p{
    margin: 10px;
    font-size: large;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}
.about-section p span{
    margin: 10px 0;
}
.about-section img{
    width: 50%;
    margin: 10px;
}

.about-section input{
    margin-top: 20px;
}
@media screen and (max-width: 550px){
    .about-section {
    flex-direction: column;
}
.about-section img{
    width: 100%;
    margin: 0;
}
}