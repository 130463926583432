.footer {
  background-color: var(--first-color);
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px 40px 60px 40px;
  color: #efefef;
  margin-top: 20px;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;

}
.footer .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.footer .flex .part-one {
  border-right: 1px solid var(--white);
  width: 40%;
  margin-right: 20px;
  transition: 0.5s all;
}
.footer .flex .part-one p {
  font-size: 17px;
}
.footer .flex .part-one h4 {
  font-size: 22px;
}
.part-one .social-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
}
.part-one .social-icons img {
  filter: invert(1);
  max-width: 35px;
  margin: 10px 20px;
  cursor: pointer;
}
.footer .flex .part-two {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 50%;
  position: relative;
}
.footer .flex .part-two form {
  width: 100%;
}


.footer .copyright {
  margin-top: 50px;
}

.footer-links{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
grid-column-gap: 20px;
    list-style: none;
    padding: 0;
}
.footer-links li{
    margin: 10px 4px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
 .top-separator{
     width: 100%;
 }
@media screen and (max-width: 880px) {
  .footer .flex {
    flex-direction: column;
  }
  .footer .flex .part-one {
    border-right: 0;
    border-bottom: 1px solid var(--white);
    padding: 30px 0;
    width: 100%;
    transition: 0.5s all;
  }
  .top-separator{
      border-right: 0;
    border-top: 1px solid var(--white);
    padding: 30px 0;
    width: 100%;
    transition: 0.5s all;
  }
  .footer .flex .part-one {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer .flex .part-two {
    width: 100%;
    transition: 0.5s all;
  }
}

@media screen and (max-width: 620px) {
  .footer {
    padding: 20px;
    padding-bottom: 70px
  }
  .footer .flex .part-one {
  }
  .footer .flex .part-one .left {
    width: 50%;
  }
  .footer .flex .part-one .right {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 50%;
  }
  .footer .flex .part-one h4 {
    font-size: 15px;
  }
  .footer .flex .part-one p {
    font-size: 12px;
  }
  .part-one .social-icons img {
    filter: invert(1);
    max-width: 25px;
  }
}

@media screen and (max-width: 350px) {
  .footer-links{
    grid-template-columns: repeat(2, 1fr);

}
}


@media screen and (max-width: 300px) {
  .footer .flex .part-one {
    flex-direction: column;
  }
}