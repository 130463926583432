.view-container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    

}
.view-container .card{
width: 90%;
    background-color: var(--white-card);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 20px 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, .3);
    padding: 10px;
    position: relative;
    border: 1px solid #fff;
    

}
.view-container .card .img{
    width: 40%;
    position: relative;
}
.view-container .card .img img{
    width: 100%;
}
.view-container .card .info{
    width: 60%;
    position: relative;
    padding: 0 20px;
}

.view-container .card .info h1{
    margin: 0;
    font-size: xx-large;
}
.view-container .card .info .description{
    letter-spacing: 1px;
    width: 80%;
    margin: 0;
}
.view-container .card .date{
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    width: 90%;
    font-size: large;
}
@media screen and (max-width: 780px) {
    .view-container .card{
        flex-direction: column;
        width: 96%;
    }
    .view-container .card .img{
    width: 100%;
}
 .view-container .card .img img{
    width: 100%;
}
.view-container .card .info{
    width: 100%;
    padding: 0;
}
.view-container .card .info .date {
    width: 100%;
}
.view-container .card .info h1{
   font-size: x-large;
   text-align: center;
}
}