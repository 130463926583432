header {
  margin: 0;
  padding: 2em 5em 4em;
  color: #efefef;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
}
.auteur {
  align-self: flex-start;
}
.threeD {
  text-shadow: 0 0.02em 0.02em #e9e9e9, 0 0.02em 0em #696969,
    0 0.04em 0em #636363, 0 0.06em 0.02em #5a5a59, 0 0.08em 0.04em #5e5e5e,
    0 0.1em 0.06em #525252, 0 0.12em 0.06em #4f504f, 0 0.14em 0.04em #4e4e4e,
    0 0.16em 0.02em #464646, 0 0.18em 0em #444444, 0 0.2em 0em #3f3f3f,
    0 0.2em 0.08em rgba(0, 0, 0, 0.2), 0 0.2em 0.16em rgba(0, 0, 0, 0.4),
    0 0.2em 0.24em rgba(0, 0, 0, 0.6), 0 0.28em 0.2em rgba(0, 0, 0, 0.2),
    0 0.36em 0.2em rgba(0, 0, 0, 0.2);
}
header > h3 {
  align-self: flex-end;
}
header::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: skewY(-4deg);
  background: var(--first-color);
  transform-origin: bottom left;
  z-index: -1;
}
header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: skewY(8deg);
  background: var(--first-color);
  transform-origin: bottom right;
  z-index: -1;
}

@media screen and (max-width: 650px) {
  header {
    margin: 0;
    padding: 2em 1em 4em;
  }
  header > h1 {
    font-size: 22px;
  }
  header > h2 {
    font-size: 18px;
  }
  header > h3 {
    font-size: 14px;
    margin-top: 20px;
  }
  header > h4 {
    font-size: 12px;
  }
}
