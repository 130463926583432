.search-container{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}
.search-input {
  -webkit-appearance: none;
  padding: 7px 7px 7px 12px;
  width: 50%;
  border: 1px solid #e8eaed;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  outline: 0;
  transition: all 150ms ease;
}
.search-input:focus{
    border: 1px solid var(--third-color);
    transition: all 150ms ease;
}

@media screen and (max-width: 580px) {
    .search-input{
        width: 90%;
    }
}